import * as yup from 'yup';
import { Brand, enums, User } from '../enums'

const REQ_ERROR = 'This field is required'


const ForgetPassword = yup.object({
    email    : yup.string().email().required(),
})

const ResetPassword = yup.object({
    password : yup.string().min(8).required()
})

const LoginVld = yup.object({
    email    : yup.string().email().required(),
    password : yup.string().min(8).required()
})

const SignupVld = yup.object({
    fullName   : yup.string().required(REQ_ERROR),
    email      : yup.string().email().required(REQ_ERROR),
    // phone      : yup.string().required().matches(/^\d{1,10}$/, 'Phone number is not valid'),
    // dialingCode: yup.string().matches(
    //     /^(\+{1}\d{1,3}|\+{1}\d{1,4})$/,
    //     "Please enter valid code eg(+61)"
    //   ).required("required"),
    password   : yup.string().min(8).required(REQ_ERROR)
})


const AboutBusiness = yup.object({
})





const locationSchema = yup.object({
    lat         : yup.number().required(REQ_ERROR),
    lng         : yup.number().required(REQ_ERROR),
    address     : yup.string().required(REQ_ERROR),
    city        : yup.string().required(REQ_ERROR),
    country     : yup.string().required(REQ_ERROR),
    countryCode : yup.string(),
    offset      : yup.string(),
    state       : yup.string()
})


const Location = yup.object({
})

const Branding = yup.object({
    brandName      : yup.string().required(REQ_ERROR),
    description    : yup.string(),
    brandType      : yup.string().required().oneOf(Object.values(Brand.BrandTypes)),
    phone          : yup.string(),//mi.n(8).matches(/^\d{1,10}$/, 'Phone number is not valid'),//.required()
    dialingCode    : yup.string(),//.matches(/^(\+?\d{1,3}|\d{1,4})$/),
    businessService: yup.string(),//.required(REQ_ERROR)
    location : locationSchema,
    website       : yup.string().required(REQ_ERROR),
    brandLogo       : yup.string(),
    brandCover      : yup.string(),
    // brandCoverFile  : yup.mixed()
    //                     .test('coverSize', 'File too large, it can be maximum of 5mb each.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true) // true will neglect the validation case
    //                     .test('coverType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true),
    // brandLogoFile   : yup.mixed().required()
    //                     .test('imageSize', 'File too large, it can be maximum of 5mb each.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true)
    //                     .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
    //                     .when('brandLogo',{
    //                         is  : value => !value,
    //                         then: yup.mixed().required(REQ_ERROR)
    //                     })
})

const OnBoard = {
    AboutBusiness : AboutBusiness,
    Location      : Location,
    Branding      : Branding,
    
}





export {
    LoginVld,
    SignupVld,
    OnBoard,
    ForgetPassword,
    ResetPassword,
    locationSchema
}