import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { TransactionService } from '../services';
import localforage from 'localforage';
import { LayoutContext } from '../context/Layout.context';
import Loader from '../components/Loader';
import axios from 'axios';

function Transaction() {

    const [loader, setLoader] = React.useState(true)
    const [rows, setRows] = React.useState([])
    const [a, setA] = React.useState(false)

    function createData(name, amount, point, removePoints, currentPoints, type, platform, deal, date) {
        return {name, amount, point, removePoints, currentPoints, type, platform, deal, date};
      }

    React.useEffect(async() => {
        setLoader(true)
        setRows([])
        let transaction = await localforage.getItem('transaction')
        if(transaction){
            setRows(transaction)
            setLoader(false)
        }
        let brand = await localforage.getItem('brand')
        let abc = await TransactionService.GetTransactionsbyBrandId({query:{brandId:brand._id}})
        console.log('abc',abc);
        if(abc?.response?.data?.length>0){
            const newRows = [];
            for (const element of abc.response.data) {
                console.log('element',element?.pass?.userData?.firstName);
                if(element.type&&element.platform){
                    newRows.push(
                        createData(
                            element?.pass?.userData?.firstName || '-',
                            element?.amount || '-' , 
                            element?.points || '-', 
                            element?.removePoints || '-',
                            element?.currentPoints || '-',
                            element?.type, 
                            element?.platform, 
                            element?.deal?.name || '-' , 
                            new Date(element.created).toDateString(), 
                        )
                    )
                    
                }
            }
            setRows(newRows.reverse());
            localforage.setItem('transaction',newRows.reverse())
        }

        // rows.sort((a, b) => new Date(b.created) - new Date(a.created));
        
        setTimeout(() => {
            // setRows([...rows])
            setLoader(false)
            
        }, 3000);
        setA(true)

    }, [a])
    

    const columns = [
        { id: 'name', label: 'Name', minWidth: 170 },
        { id: 'amount', label: 'Amount', minWidth: 100 },
        { id: 'point', label: 'Points', minWidth: 100 },
        { id: 'removePoints', label: 'Used Pts', minWidth: 100 },
        { id: 'currentPoints', label: 'Current Pts', minWidth: 100 },
        { id: 'type', label: 'Type', minWidth: 170 },
        { id: 'platform', label: 'Platform', minWidth: 170 },
        { id: 'deal', label: 'Deal', minWidth: 170 },
        { id: 'date', label: 'Date', minWidth: 170 },
        // { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
        // {
        //   id: 'population',
        //   label: 'Population',
        //   minWidth: 170,
        //   align: 'right',
        //   format: (value) => value.toLocaleString('en-US'),
        // },
        // {
        //   id: 'size',
        //   label: 'Size\u00a0(km\u00b2)',
        //   minWidth: 170,
        //   align: 'right',
        //   format: (value) => value.toLocaleString('en-US'),
        // },
        // {
        //   id: 'density',
        //   label: 'Density',
        //   minWidth: 170,
        //   align: 'right',
        //   format: (value) => value.toFixed(2),
        // },
      ];
      
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(1000);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(+event.target.value);
    //     setPage(0);
    // };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(Math.max(0, Math.ceil(rows.length / +event.target.value) - 1));
    };

    const layout = React.useContext(LayoutContext);
    React.useEffect(() => {
        layout.setLayout({
          title: "Transaction",
          backTitles: [],
          edit: false,
          switch: "",
          borderBottom: false,
          button: (
          <></>
          ),
        });
      }, []);


    
  return (
    <div id='Transaction'>
        {loader?<Loader baseHeight={'calc(100vh - 150px)'} />:<div>
            {
            rows?.length>0?
            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
                <TableContainer sx={{ height: 'calc(100vh - 112px)' }}>
                    <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell className='B14M'
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            >
                            {column.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return (
                            <TableRow hover className='B14R' role="checkbox" tabIndex={-1} key={row.code}>
                                {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                    <TableCell className='B14R' key={column.id} align={column.align}>
                                    {column.format && typeof value === 'number'
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                );
                                })}
                            </TableRow>
                            );
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100, 1000]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Paper>:
                <div id="midloader" className="middle" style={{height: 'calc(100vh - 64px)'}}>
                <div>
                {/* <div class="loader"></div> */}
                </div>
                <div className="Heading28B color-neutral100">No Customers</div>
                <div className="Body22R color-neutral70 mt_8">When you start getting new customers, It'll show up here
                </div>
              </div>
              }

        </div>}
                
    </div>
  )
}

export default Transaction